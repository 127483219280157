import "bootstrap";
import "./_bootstrap";

import "laravel-datatables-vite";
import select2 from "select2";

select2();

import.meta.glob(["../icons/**"]);
import { createApp, defineAsyncComponent } from "vue";
import { i18nVue } from "laravel-vue-i18n";

window.vueApp = createApp({})
    .component(
        "AllTeacherReport",
        defineAsyncComponent(() => import("@/components/AllTeacherReport.vue"))
    )
    .component(
        "AllManageReport",
        defineAsyncComponent(() => import("@/components/AllManageReport.vue"))
    )
    .component(
        "TeachersTimes",
        defineAsyncComponent(() => import("@/components/TeachersTimes.vue"))
    )
    .component(
        "UserSchedules",
        defineAsyncComponent(() => import("@/components/UserSchedules.vue"))
    )
    .component(
        "SettingSchedules",
        defineAsyncComponent(() => import("@/components/SettingSchedules.vue"))
    )
    .component(
        "AddClassForm",
        defineAsyncComponent(() =>
            import("@/components/classes/AddClassForm.vue")
        )
    )
    .component(
        "EditClassForm",
        defineAsyncComponent(() =>
            import("@/components/classes/EditClassForm.vue")
        )
    )
    .component(
        "AllUserSchedules",
        defineAsyncComponent(() => import("@/components/AllUserSchedules.vue"))
    )
    .component(
        "OneOnOneShow",
        defineAsyncComponent(() =>
            import("@/components/schedule/OneOnOneShow.vue")
        )
    )
    .component(
        "GroupClassShow",
        defineAsyncComponent(() =>
            import("@/components/schedule/GroupClassShow.vue")
        )
    )
    .component(
        "AttendShow",
        defineAsyncComponent(() => import("@/components/attend/show.vue"))
    )
    .use(i18nVue, {
        resolve: async (lang) => {
            const langs = import.meta.glob("../../public/lang/*.json");
            return await langs[`../../public/lang/${lang}.json`]();
        },
    })
    .mount("#app");

window.postDataVue = (eventType, data) => {
    document.dispatchEvent(
        new CustomEvent("PostDataVueEvent", {
            detail: {
                type: eventType,
                data: data,
            },
        })
    );
};
